import React from "react"
// import { Link } from "gatsby"
import {Helmet} from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoBerkley from "../../assets/old/img/logos-ase/ico-berkley.png"

export default () => (
  <SiniestrosLayout subCompaniaActivo="berkley">
    <Helmet>
      <title>Berkley Argentina Seguros - Siniestros</title>
      <meta
        name="description"
        content="Servicio de asistencia Berkley Argentina Seguros: 0810-555-3031."
      />
    </Helmet>

    <hr className="mb-4"/>
    {/* Quotation */}
    <div className="card blog-card mb-2">
      <div className="card-body px-sm-5 py-5 bg-secondary">
        <img
          className="float-md-right pb-2"
          src={icoBerkley}
          alt="Berkley Argentina Seguros"
        />
        <h4 className="lead post-title text-muted">Berkley Argentina Seguros</h4>
        <p className="">
          Servicio de asistencia: 0810-555-3031 - <i>De lunes a viernes, de 10 a 18</i> <br/>
          Servicio de auxilio mecánico: 0800-555-0465
        </p>
      </div>
    </div>

    <h6 className="pt-5">Servicio de Atención al Asegurado</h6>
    <p>
      La compañía de seguros dispone de un Servicio de Atención al Asegurado que atenderá las consultas y reclamos que
      presenten los tomadores de seguros asegurados, beneficiario y/o derechohabientes, y los tramitará en un plazo
      máximo de 30 días hábiles desde su presentación.
    </p>
    <p>
      De transcurrir el plazo sin que haya sido resuelto, o que haya sido denegado su admisión o desestimado, total o
      parcialmente, podrá acudir al Departamento de Orientación y Asistencia del Asegurado (D.O.A.A,), dependiente de la
      Superintendencia de Seguros de la Nación. Dirigiéndose a:
    </p>
    <p>
      Julio A. Roca 721, (C1067ABC) Ciudad Autónoma de Buenos Aires, en el horario de 10:30 a 17:30 hs; o bien
      comunicándose telefónicamente al 0-800-666-8400 o por correo electrónico a: consultasydenuncias@ssn.gob.ar Dicho
      Servicio está integrado por:
    </p>
    <p>
      <b>RESPONSABLE:</b> <br/>
      Vidarte Bassi Selva. (svidarte@berkley.com.ar)
      <br/><br/>
      <b>1° ASISTENTE:</b> <br/>
      Ambühl Elena. (eambuhl@berkley.com.ar)
      <br/><br/>
      <b>2° ASISTENTE:</b> <br/>
      Piermarini Raúl. (rpierma@berkleyseguros.com)
    </p>

    <hr className="pb-4"/>


  </SiniestrosLayout>
)
